import React, { useEffect, useState } from 'react';

import { navigate } from 'gatsby';
import { useDispatch, useSelector} from 'react-redux';
import { createLoadingSelector } from '../../reducers/api';
import Layout from '../../components/v2/Layout';
import LoaderView from '../../components/LoaderView';

import { Grid, Typography, Card, CardContent, Chip, TextField, Select, MenuItem, Button, CircularProgress, Backdrop} from '@material-ui/core';

import { Person as NameIcon, LocalPhone as PhoneIcon, Cake as BirthDateIcon, Opacity as BloodGroupIcon, Wc as GenderIcon, BusinessRounded as PartnerIcon, CloudDoneRounded as SaveDoneIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import BirthDateInput from '../../components/BirthDateInput';
import useActiveSession from '../../hooks/useActiveSession';

import { patchUpdateMe, patchUpdateMeProfileImage } from '../../apis';
import { getPatientRecord, postPatientRecord } from '../../actions/assessmentInfo';
import { getCorporateCardList } from '../../actions/partners';
import { changedProfile } from '../../actions/session';

import ProfileSelector from '../../components/ProfileSelector';

const oldProfileData = {
  avatar: 'https://service.moronline.me/static/images/avatar.png',
  first_name: '',
  last_name: '',
  phone: '',
  birthday: '',
  gender: '',
  blood_group: '',
}

const useStyles = makeStyles((theme) => ({
  flexCenter:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  },
  cardLogin: {
    position: 'relative',
    width: '100%',
    height: '700px',
    borderRadius: '33px',
    boxShadow: '0px 5px 35px rgba(0, 0, 0, 0.25)',
    background: 'linear-gradient(135deg, rgba(255,255,255,1) 0%,  rgba(255,255,255,1) 18%,  rgba(255,255,255,1) 145%)',
    borderWidth: '0',
    display:'flex',
    flexDirection: 'column',
    justifyContent:'center',
    [theme.breakpoints.down('lg')]: {
      minWidth: '512px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      borderRadius: '25px',
    },
    [theme.breakpoints.down(350)]: {
      minWidth: '100%',
      borderRadius: '20px',
    },
  },
  cardContent: {
    position: 'relative',
    display: 'grid',
    gridTemplateRows:'4.34fr 5.66fr',
    height: '100%',
    width: '100%',
    gap: '0px',
    padding: '0px',
  },
  cardHeaderGridContent:{
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow:'hidden',
  },
  cardLowerGridContent:{
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 20px 15px 20px'
  },
  imgBlurBgContainer:{
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  imgBlur:{
    position: 'relative',
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  overlayBlur:{
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(135deg, rgba(125,125,125,0.5) 0%,  rgba(29,152,229,0.5) 145%)',
    backdropFilter: 'blur(15px)',
  },
  textTitle:{
    position: 'relative',
    color: 'white',
    fontSize: '25px',
  },
  nameTitle:{
    position: 'relative',
    color: 'white',
    fontSize: '20px',
  },
  nameTitleError:{
    position: 'relative',
    color: 'rgb(255,60,60)',
    fontSize: '20px',
  },
  chipBadge:{
    position: 'relative',
    fontFamily: 'QuickSand',
    fontSize: '11px',
    fontWeight: 700,
    height: 'auto',
    boxShadow: '0 4px 15px rgb(0,0,0,0.25)',
    color: 'white',
    padding: '4px 8px',
  },
  imgbadge: {
    height: '17px',
    width: '17px',
  },
  inputEditContainer:{
    width: '80%',
    height: '40px',
    position: 'relative',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '0.3fr 1fr 2fr',
    [theme.breakpoints.down('xs')]: {
      width: '92.5%',
      gridTemplateColumns: '0.3fr 1fr 1.5fr',
    },
  },
  inputLabel:{
    position: 'relative',
    fontSize: '15px',
  },
  inputField:{
    position: 'relative',
  },
  inputMultiField:{
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 0fr',
  },
  btnSave:{
    width: '80%',
    marginTop: '35px',
    [theme.breakpoints.down('xs')]: {
      width: '92.5%',
    },
  },
  
  
  transDisplayShow:{ opacity: '1.0', pointerEvents:'auto', animation: '$card-fade-show 0.25s ease 1', animationFillMode: 'forwards',},
  transDisplayHide:{ opacity: '0.0', pointerEvents:'none', animation: '$card-fade-hide 0.25s ease 1', animationFillMode: 'forwards',},
  
  '@keyframes card-fade-show': {
    '0%': { opacity: '0.0'},
    '100%': { opacity: '1.0'},
  },
  '@keyframes card-fade-hide': {
    '0%': { opacity: '1.0'},
    '100%': { opacity: '0.0'},
  },
  '@keyframes helper-text-show': {
    '0%': { height: '0px', opacity: '0.0'},
    '100%': { height: '15px', opacity: '1.0'},
  },
  '@keyframes helper-text-disappear': {
    '0%': { height: '15px', opacity: '1.0'},
    '100%': { height: '0px', opacity: '0.0'},
  },
  cardSuccessContainer:{
    width: '200px', minHeight: '50px',
    boxShadow: '0 4px 15px rgb(0,0,0,0.25)',
    background: 'rgb(0,0,0,0.4)',
    borderRadius: '10px',
    backdropFilter: 'blur(15px)',
    position: 'fixed',
    top: '50%', left: '50%',
    transform: 'translate(-50%,-50%)',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'center',
    alignItems:'center',
    color:'white',
    opacity: '0',
    pointerEvents: 'none'
  },
  helperTextShow: {
    fontSize: '10px',
    height: '15px',
    animation: '$helper-text-show 0.5s ease 1',
    animationFillMode: 'forwards',
  },
  helperTextDisappear: {
    fontSize: '10px',
    height: '0px',
    transition: 'all 0.5s ease',
    animation: '$helper-text-disappear 0.5s ease 1',
    animationFillMode: 'forwards',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  errorMessageContainer:{
    width: '80%',
    minHeight: '35px',
    padding: '10px 0 0 0',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  errorMessage:{
    color: 'red',
    lineHeight: '1.0',
    fontWeight: 700,
  },
}))

function getColorBadge(val = '') {
  const res = val.toLowerCase().replaceAll(' ', '');
  if (res.includes('gold')) return 'linear-gradient(135deg, rgba(200,162,25,1.0) 0%,  rgba(255,200,1,1.0) 145%)';
  if (res.includes('standard')) return 'linear-gradient(135deg, rgba(114,120,124,1.0) 0%,  rgba(117,125,145,1.0) 145%)';
  if (res.includes('platinum')) return 'linear-gradient(90deg, rgba(65,75,84,1.0) 0%,  rgba(98,98,98,1.0) 65%, rgba(65,75,84,1.0) 145%)';
  if (res.includes('partner')) return 'linear-gradient(90deg, rgba(0,133,255,1.0) 0%,  rgba(38,170,252,1.0) 65%, rgba(0,133,255,1.0) 145%)';
  return '#ffffff';
}
function getBadgeAsset(val = ''){
  const res = val.toLowerCase().replaceAll(' ', '');
  if(res.includes('partner')){
    return <PartnerIcon style={{height: '100%', width:'auto',color: 'white'}}/>
  }
  return null
}

export default function EditProfile(props) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.session);
  const { cardName } = useSelector(state => state.partners);
  const patientRecord = props.location.state?.patientRecordData;
  const isLoading = false;

  // useActiveSession(() => {
  //   dispatch(getPatientRecord());
  //   dispatch(getCorporateCardList())
  // }, []);

  const [firstName, setFirstName] = useState(oldProfileData.first_name)
  const [lastName, setLastName] = useState(oldProfileData.last_name)
  const [phone, setPhone] = useState(oldProfileData.phone)
  const [phoneShown, setPhoneShown] = useState('')
  const [birthday, setBirthday] = useState(oldProfileData.birthday)
  const [gender, setGender] = useState('')
  const [bloodGroup, setBloodGroup] = useState('')

  const isSaveButtonDisabled = lastName && firstName ? false : true
  const [errorSaveEditMessage, setErrorSaveEditMessage] = useState('');

  const [imageFile, setImageFile] = useState(null);
  const [imageURLs, setImageURLs] = useState(oldProfileData.avatar);

  const [isImgChange, setIsImgChange] = useState(false);

  const [isUpdateProgress, setIsUpdateProgress] = useState(false)
  const [isSuccessDialogShown, setIsSuccessDialogShown] = useState(false)
  const [isSuccessDialogEverShown, setIsSuccessDialogEverShown] = useState(false);


  const handleChangeFirstName = (event) => {
    const cleanFirstName = event.target.value.trim();
    setFirstName(cleanFirstName);
  }
  const handleChangeLastName = (event) => {
    const cleanLastName = event.target.value.trim();
    setLastName(cleanLastName);
  }
  const handleChangeBirthday = (value) => {
    setBirthday(value);
  }
  const handleChangePhone = (value) => {
    const phoneValue = value;
    const cleanedPhoneNumber = phoneValue.replace(/\D/g, '').slice(0, 10);
    const formattedPhoneNumber = cleanedPhoneNumber.replace(
      /(\d{3})(\d{0,3})(\d{0,4})/, (_, p1, p2, p3) => {
      let result = p1;
      if (p2) result += `-${p2}`;
      if (p3) result += `-${p3}`;
      return result;
    });

    setPhoneShown(formattedPhoneNumber)
    setPhone(formattedPhoneNumber.replace(/\D/g, ''))
  }
  const handleChangeGender = (event) => {
    setGender(event.target.value)
  }
  const handleChangeBloodGroup = (event) => {
    setBloodGroup(event.target.value);
  }

  const handleSaveEdit  = async () => {
    setIsUpdateProgress(true);
    setErrorSaveEditMessage(null);

    const updateMePayload = {
      'first_name': firstName,
      'last_name': lastName,
    };
    const updatePatientRecordPayload = {
      'first_name': firstName,
      'last_name': lastName,
      'birthday': birthday,
      'gender': gender,
      'blood_group': bloodGroup
    };
    const updateProfileImagePayload = new FormData();
    updateProfileImagePayload.append(
      "avatar", imageFile
    );
    var isUpdateComplete = true;

    const isDataMeChange = Object.keys(updateMePayload).some(key => updateMePayload[key] !== oldProfileData[key] );
    const isDataPatientRecordChange = Object.keys(updatePatientRecordPayload).some(key => updatePatientRecordPayload[key] !== oldProfileData[key] );

    if(isImgChange){
      try {
        await patchUpdateMeProfileImage(updateProfileImagePayload);
        setIsImgChange(false); // reset check
      }
      catch(e){
        setErrorSaveEditMessage(e.message);
        isUpdateComplete = false;
      }
    }
    if(isDataMeChange){
      try {
        await patchUpdateMe(updateMePayload);
        Object.keys(updateMePayload).map(key => oldProfileData[key] = updateMePayload[key]);
      }
      catch(e){
        setErrorSaveEditMessage(e.message);
        isUpdateComplete = false;
      }
    }
    if(isDataPatientRecordChange){
      try {
        await dispatch(postPatientRecord(updatePatientRecordPayload));
        Object.keys(updatePatientRecordPayload).map(key => oldProfileData[key] = updatePatientRecordPayload[key]);
      }
      catch(e){
        setErrorSaveEditMessage(e.message);
        isUpdateComplete = false;
      }
    }
    if(isUpdateComplete){
      showSuccessDialog();
    }
    if (isImgChange || isDataMeChange || isDataPatientRecordChange) {
      dispatch(changedProfile(true));
    }
    setIsUpdateProgress(false)

  }

  const showSuccessDialog = () => {
    setIsSuccessDialogEverShown(true);
    setIsSuccessDialogShown(true);
    const timer = setTimeout(() => {
      setIsSuccessDialogShown(false);
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  };


  useEffect(()=>{
    if(user){
      oldProfileData['first_name'] = user['first_name'];
      oldProfileData['last_name'] = user['last_name'];
      oldProfileData['phone'] = user['phone'];
      oldProfileData['avatar'] = user['avatar'];

      setFirstName(oldProfileData.first_name);
      setLastName(oldProfileData.last_name);
      setPhone(oldProfileData.phone);
      handleChangePhone(oldProfileData.phone);
      setImageURLs(oldProfileData.avatar);
    }

  },[user])

  useEffect(()=>{
    if(patientRecord){
      oldProfileData['birthday'] = patientRecord?.birthday;
      oldProfileData['gender'] = patientRecord?.gender;
      oldProfileData['blood_group'] = patientRecord?.blood_group;

      setBirthday(oldProfileData.birthday);
      setGender(oldProfileData.gender);
      setBloodGroup(oldProfileData.blood_group);
    }
    // else{
      // navigate('/partners/profile');
    // }
  },[patientRecord])
  

  return (
    <Layout
      bg="bg3"
      title='Edit Profile'
    >
      <LoaderView loading={user? isLoading : true } >

      <div className={classes.flexCenter} style={{ height: '100vh', padding: 0}}>
        <Grid container spacing={0}
          justifyContent='center'
          alignItems='center'
        >
          <Grid item xs={12} sm={5} md={5} xl={5} className={classes.flexCenter}>

            <Card variant="outlined" className={classes.cardLogin}>
              <CardContent className={classes.cardContent}>
                <div className={classes.cardHeaderGridContent}>
                  <div className={classes.imgBlurBgContainer}>
                    {
                      imageURLs?
                      <img className={classes.imgBlur} src={imageURLs} />
                      :
                      <img className={classes.imgBlur} src={require(`${'../../assets/images/profile_placeholder.png'}`)} alt='' />
                    }
                    
                  </div>
                  <div className={classes.overlayBlur}></div>
                  <Typography className={classes.textTitle} variant='h6' component='h1' color='textPrimary'>
                    แก้ไขข้อมูลโปรไฟล์
                  </Typography>

                  <ProfileSelector
                    stateCurrentImgURLs={imageURLs}
                    stateIsImgChange={isImgChange}
                    onIsImgChange={setIsImgChange}
                    onGetImgFile={setImageFile}
                    onGetImgUrls={setImageURLs}
                    usePreview={true}
                  />
                  
                  {
                    firstName && lastName ?
                    <Typography className={classes.nameTitle} variant='h6' component='h1' color='textPrimary'>
                      {firstName+' '+lastName}
                    </Typography>
                    :
                    <Typography className={classes.nameTitleError} variant='h6' component='h1' color='error'>
                      {'กรุณาระบุชื่อของคุณ'}
                    </Typography>
                  }

                  {
                    cardName?
                      <Chip
                        className={classes.chipBadge}
                        style={{ background: getColorBadge('partner')}}
                        label={cardName}
                        icon={
                          <div className={classes.imgbadge} >
                            {getBadgeAsset('partner')}
                          </div>
                        }
                      />
                    : <Chip 
                        className={classes.chipBadge}
                        style={{ background: getColorBadge('partner')}}
                        label='Employee'
                        icon={
                          <div className={classes.imgbadge} >
                            {getBadgeAsset('partner')}
                          </div>
                        }
                      />
                  }

                </div>
                <div className={classes.cardLowerGridContent}>

                  <div className={classes.inputEditContainer}>
                    <NameIcon style={{color:'#274A8E'}}/>
                    <div className={classes.inputLabel}>
                      <p>ชื่อ</p>
                    </div>
                    <TextField label="" variant="standard"
                      className={classes.inputField}
                      onChange={handleChangeFirstName}
                      required
                      value={firstName}
                      error={firstName? false:true}
                      helperText={firstName?' ':'Please fill in the field'}
                      FormHelperTextProps={{
                        className: !firstName?
                          (classes.helperTextShow) :
                          (classes.helperTextDisappear)
                      }}
                    />
                  </div>

                  <div className={classes.inputEditContainer}>
                    <NameIcon style={{color:'#274A8E'}}/>
                    <div className={classes.inputLabel}>
                      <p>นามสกุล</p>
                    </div>
                    <TextField label="" variant="standard"
                      className={classes.inputField}
                      onChange={handleChangeLastName}
                      required
                      value={lastName}
                      error={lastName? false:true}
                      helperText={lastName?' ':'Please fill in the field'}
                      FormHelperTextProps={{
                        className: !lastName?
                          (classes.helperTextShow) :
                          (classes.helperTextDisappear)
                      }}
                    />
                  </div>

                  <div className={classes.inputEditContainer}>
                    <BirthDateIcon style={{color:'#274A8E'}}/>
                    <div className={classes.inputLabel}>
                      <p>วัน/เดือน/ปี เกิด</p>
                    </div>
                    <div className={classes.inputMultiField}>
                      <BirthDateInput
                        native
                        className={classes.inputField}
                        disable={false}
                        defaultValue={birthday}
                        value={birthday}
                        onChange={handleChangeBirthday}
                      />
                    </div>
                  </div>

                  <div className={classes.inputEditContainer}>
                    <PhoneIcon style={{color:'#274A8E'}}/>
                    <div className={classes.inputLabel}>
                      <p>เบอร์โทร</p>
                    </div>
                    <TextField label="" variant="standard"
                      className={classes.inputField}
                      onChange={e=>handleChangePhone(e.target.value)}
                      required
                      value={phoneShown}
                      disabled={true}
                    />
                  </div>

                  <div className={classes.inputEditContainer}>
                    <GenderIcon style={{color:'#274A8E'}}/>
                    <div className={classes.inputLabel}>
                      <p>เพศ</p>
                    </div>
                    <Select
                      value={gender}
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={handleChangeGender}
                      defaultValue={''}
                    >
                      <MenuItem value="" disabled>
                        <em>โปรดระบุ</em>
                      </MenuItem>
                      <MenuItem value={'male'}>ชาย</MenuItem>
                      <MenuItem value={'female'}>หญิง</MenuItem>
                      <MenuItem value={'other'}>อื่นๆ</MenuItem>
                    </Select>
                  </div>

                  <div className={classes.inputEditContainer}>
                    <BloodGroupIcon style={{color:'#274A8E'}}/>
                    <div className={classes.inputLabel}>
                      <p>กรุ๊ปเลือด</p>
                    </div>
                    <Select
                      value={bloodGroup}
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={handleChangeBloodGroup}
                      defaultValue={''}
                    >
                      <MenuItem value="" disabled>
                        <em>โปรดระบุ</em>
                      </MenuItem>
                      <MenuItem value={'A'}>A</MenuItem>
                      <MenuItem value={'B'}>B</MenuItem>
                      <MenuItem value={'O'}>O</MenuItem>
                      <MenuItem value={'AB'}>AB</MenuItem>
                    </Select>
                  </div>

                  <Button
                    variant="contained"
                    color='primary'
                    className={classes.btnSave}
                    disabled={isSaveButtonDisabled}
                    onClick={()=>handleSaveEdit()}
                  >
                    <span>{'บันทึกข้อมูล'}</span>
                  </Button>
                  
                  {
                    errorSaveEditMessage?
                    <div className={classes.errorMessageContainer}>
                      <Typography variant='subtitle1' component='h6' className={classes.errorMessage}>
                        {errorSaveEditMessage}
                      </Typography>
                    </div>
                    : null
                  }

                </div>

              </CardContent>
            </Card>
            
            <Backdrop className={classes.backdrop} open={isUpdateProgress}>
              <CircularProgress/>
            </Backdrop>

            <Card className={
              isSuccessDialogShown? `${classes.cardSuccessContainer} ${classes.transDisplayShow}`
              : isSuccessDialogEverShown? `${classes.cardSuccessContainer} ${classes.transDisplayHide}`
              : classes.cardSuccessContainer
            }>
              
              <SaveDoneIcon style={{fontSize: '35px'}}/>
              <Typography variant='h6' component='h1'>
                บันทึกข้อมูลเสร็จสิ้น
              </Typography>
            
            </Card>
            
          </Grid>
        </Grid>

      </div>
      </LoaderView>
    </Layout >
  )
}

